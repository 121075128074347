import axios from "axios";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { FiX, FiSearch, FiArrowDown, FiArrowUp } from "react-icons/fi";
import Pagination from "./Pagination";
import Skeleton from "react-loading-skeleton";
import { useHistory, useLocation, Redirect, Route } from "react-router-dom";
import PupilContext from "./context/PupilContext";
import { useRef } from "react";
import { routeUrl } from "../Components/utils/RouteUrl";
import {
  clearSearchField as clearSearchFieldAction,
  closeSearchCollapse,
  decreaseCursor,
  increaseCursor,
  loading,
  notLoading,
  openSearchCollapse,
  setCurrentPage as setCurrentPageAction,
  setIdToSearch as setIdToSearchAction,
  setSearchField as setSearchFieldAction,
  setSearchResult,
} from "../constants/reducerActions";
import searchBarReducer from "../reducers/searchBarReducer";
import { debounce } from "lodash";
import { settings } from "./utils/Settings";
import MainContext from "./context/MainContext";

const initialState = {
  searchField: "",
  searchFieldCollapsed: true,
  searchResults: [],
  currentPage: 1,
  searchResultsPerPage: 10,
  isLoading: true,
  cursor: -1,
  idToSearch: "",
};

const SearchBar = (props) => {
  // All Context Variables

  const RoleID = localStorage.getItem("userRole");

  const { state: mainContext } = useContext(MainContext);
  const {
    setActivePupilIdHandler,
    setSchoolsId,
    setSelectedPupilex,
    SelectedPupilex,
    setvaluepupil,
    setsearchesValue,
    searchesValue,
    setselectedClient,
    selectedClient,
    deletevalues,
    setdeletevalues,
    setKS5,
    setUrnstts,
    Urnstts,
    setattnvalue,
    setcictype,
    cictype,
    
  } = useContext(PupilContext);
  const [SearchContent, setSearchContent] = useState("");
  const [sortingValueasc, setSortingValueasc] = useState("school asc");
  // School Id
  const schoolId = localStorage.getItem("schoolId");

  // Getting userId from local storage
  const userId = localStorage.getItem("userId");

  const [state, dispatch] = useReducer(searchBarReducer, initialState);

  const {
    searchField,
    searchFieldCollapsed,
    searchResults,
    currentPage,
    searchResultsPerPage,
    isLoading,
    cursor,
    idToSearch,
  } = state;

  // through this property we can modify searching
  const alphabetCount = 0;

  const indexOfLastResult = currentPage * searchResultsPerPage;
  const indexOfFirstResult = indexOfLastResult - searchResultsPerPage;
  const currentResults =
    searchResults && searchResults.slice(indexOfFirstResult, indexOfLastResult);

  const searchInputRef = useRef();

  const history = useHistory();

  // Change on searchField
  useEffect(() => {
    dispatch({ type: loading });
    fetchSearchResult(searchField);
  }, [searchField]);

  useEffect(() => {
    if (window.innerWidth < 1180) {
      searchInputRef.current.focus();
    }
  }, []);

  // On change method for search Pupil
  const searchPupil = (e) => {
    const currentValue = e.target.value;
    setSearchContent(e.target.value);
    const currentValueWithoutSpaces = currentValue.trim();
    dispatch({
      type: setSearchFieldAction,
      payload: {
        value: currentValueWithoutSpaces,
        searchCollpase:
          currentValueWithoutSpaces.length > alphabetCount ? false : true,
      },
    });
  };

  // Checking if width of window is of mobile screen then its hide the whole search bar
  const hideForMobile = () => {
    if (window.innerWidth < 1180) {
      props.setSearchCollapse(false);
    }
  };

  // Clearing the search result
  const clearSearchField = () => {
    dispatch({ type: clearSearchFieldAction });
    hideForMobile();
  };

  // Handler for clearing search results
  const keyDownHandler = (e) => {
    // Checks if escape is pressed then search results will be cleared
    if (e.key === "Escape") {
      clearSearchField();
      // Handles Up key
    } else if (e.keyCode === 38 && cursor > 0) {
      dispatch({ type: decreaseCursor });
      // Handles Down key
    } else if (e.keyCode === 40 && cursor < searchResults.length - 1) {
      dispatch({ type: increaseCursor });
      // Handles Enter
    } else if (e.key === "Enter") {
      setPupilId(idToSearch);
    }
  };

  // Method for fetching search result for given keyword
  const fetchSearchResult = useCallback(
    debounce((searchKeyword) => {
      if (searchKeyword?.length > alphabetCount) {
        const urlForSearch = `${settings.API_HOST}/rest/fetchPupilListBySearch?keyword=${searchKeyword}&userId=${userId}&roleId=${mainContext.roleId}&schoolId=${schoolId}&customSorting=${sortingValueasc}`;
        axios(urlForSearch).then((res) => {
          const data = res.data.searchPupilsList;

          console.log(data);

          dispatch({ type: setSearchResult, payload: { searchResults: data } });
        });
      }
    }, 200),
    [sortingValueasc, userId, mainContext.roleId]
  );

  useEffect(() => {
    fetchSearchResult(state.searchField);
  }, [fetchSearchResult, state.searchField]);

  // useEffect(() => {
  //   fetchSearchResult();
  //   setCustomSorting(sortingValueasc)
  // }, [sortingValueasc]);

  const setPupilId = (
    pupilId,
    year,
    dateofmeeting,
    leavers,
    SchoolId,
    URN,
    careStatus
  ) => {
    // console.log("000000000" + leavers);
    // console.log("Set Pupil ID", year, dateofmeeting);
    setActivePupilIdHandler(pupilId, year, dateofmeeting, leavers);
    clearSearchField();
    const pathname = history.location.pathname;

    console.log(year);
    let str = year; // Your string

    // Replace the part after the space with a new value
    let newStr = str?.replace(/\d{4}$/, ""); // This replaces the last four digits with "2023"

    console.log(newStr); // Output: "Y13 2023"

    if (year === "Y12" || year === "Y13" || year === "Y14") {
      setKS5("KS5");
    } else {
      setKS5("");
    }
    if (leavers == "Yes") {
      history.push(routeUrl.pupil);
    } else {
      //console.log(pupilId)
      if (RoleID == 12) {
        history.push(routeUrl?.attendanceCaller);
      } else {
        history.push(routeUrl?.attendance);
        //console.log(pupilId,'4444444444444444444')
        //setattnvalue(newStr)
      }
      if (SelectedPupilex == true) {
        setSelectedPupilex(false);
      }
      // if (deletevalues == true){
      //   setdeletevalues(false)
      // }
      // else if (deletevalues == false) {
      //   setdeletevalues(true)
      // }
      console.log(pupilId);
      setUrnstts(URN);
      setcictype(careStatus);
      //  if (SelectedPupilex == true){
      //   setSelectedPupilex(false)
      //  }
      setSelectedPupilex(true);

      if (deletevalues == true) {
        setdeletevalues(false);
      }

      setdeletevalues(false);

      setvaluepupil(pupilId);
      setselectedClient(SchoolId);
      setSchoolsId(SchoolId)
      console.log(SearchContent);
      setsearchesValue(SearchContent);

      //setActivePupilId(pupilId)
    }
    // if (
    //   pathname !== routeUrl.attendanceCaller &&
    //   pathname !== routeUrl.contacts &&
    //   pathname !== routeUrl.exclusions &&
    //   pathname !== routeUrl.ks5 &&
    //   pathname !== routeUrl.pupil
    // ) {
    //   history.push(routeUrl.attendance);
    // } else if (pathname !== routeUrl.pupil) {

    //}
  };

  const setId = (id) => {
    if (id != idToSearch) {
      dispatch({ type: setIdToSearchAction, payload: { id } });
    }
  };

  const paginate = (pageNumber) =>
    dispatch({ type: setCurrentPageAction, payload: { pageNumber } });

  const setCustomSorting = (sortingColumn) => {
    //setSortingValue("");
    //console.log(sortingColumn);

    if (!sortingValueasc) {
      setSortingValueasc(`${sortingColumn} asc`);
    }
    if (sortingValueasc?.split(" ")[1] === "asc") {
      setSortingValueasc(`${sortingColumn} desc`);
    }
    if (sortingValueasc?.split(" ")[1] === "desc") {
      setSortingValueasc(`${sortingColumn} asc`);
    }
  };

  // Take parameter to show arrow for that column
  const showArrow = (sortingParameter) => {
    if (sortingValueasc === `${sortingParameter} asc`) return <FiArrowUp />;

    if (sortingValueasc === `${sortingParameter} desc`) return <FiArrowDown />;
  };
  // console.log(sortingValueasc,'@@@@@@@@@@@@@@@')

  return (
    <div className="search-bar xl:relative lg:flex items-center justify-center mr-6">
      <div
        className="overlay lg:hidden"
        onClick={() => props.setSearchCollapse(false)}
      ></div>
      <div className="relative flex flex-row items-center search-bar-input xl:ml-auto 2xl:w-4/5 w-full xl:max-w-xs">
        <input
          type="text"
          id="searchBar"
          className="rounded-custom-content w-full"
          onChange={searchPupil}
          defaultValue={searchField}
          placeholder="Name, Surname, UPN, VSNo"
          onKeyDown={keyDownHandler}
          ref={searchInputRef}
          autoComplete="off"
        />

        {searchFieldCollapsed ? (
          <FiSearch className="icon-search" />
        ) : (
          <FiX
            className="icon-search close-search-button"
            onClick={clearSearchField}
          />
        )}
      </div>
      {searchField.length > alphabetCount && (
        <>
          <div className="overlay" onClick={clearSearchField}></div>
          <div
            className="search-result bg-white"
            style={{ width: "70%", background: "#fff" }}
          >
            <table className="search-result-table child-table table-auto">
              <thead>
                <tr>
                  <th onClick={() => setCustomSorting("la")}>
                    LA <span className="mr-4">{showArrow("la")}</span>
                  </th>

                  <th
                    className="school-field"
                    onClick={() => setCustomSorting("school")}
                    style={{ width: "1%" }}
                  >
                    School Name
                    <span className="mr-4">{showArrow("school")}</span>
                  </th>

                  <th
                    onClick={() => setCustomSorting("name")}
                    className="name-field"
                  >
                    Name <span className="mr-4">{showArrow("name")}</span>
                  </th>

                  <th className="gender-field">G </th>

                  <th
                    className="hidden xl:table-cell"
                    onClick={() => setCustomSorting("upn")}
                  >
                    UPN <span className="mr-4">{showArrow("upn")}</span>
                  </th>

                  <th>DOB</th>
                  <th>Year</th>
                  <th className="hidden xl:table-cell">VS No</th>
                  <th>Care Status</th>
                  <th onClick={() => setCustomSorting("leaver")}>
                    Leaver <span className="mr-4">{showArrow("leaver")}</span>
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <>
                  <tbody className="search-loading">
                    <tr>
                      <td colSpan="4">
                        <Skeleton count={2} height={30} />
                      </td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <>
                  <tbody>
                    {currentResults &&
                      currentResults.length > 0 &&
                      currentResults.map((d, i) => {
                        //console.log(d)
                        if (d.leavers == "1") {
                          d.leavers = "Yes";
                        } else if (d.leavers == "0") {
                          d.leavers = "No";
                        }
                        cursor === i && setId(d?.pupilId);
                        return (
                          console.log(d),
                          (
                            <tr
                              key={d?.pupilId}
                              className={cursor === i ? "active" : null}
                              onClick={() =>
                                setPupilId(
                                  d?.pupilId,
                                  d?.yeargroup,
                                  d?.dateofmeeting,
                                  d?.leavers,
                                  d?.schoolid,
                                  d?.urn,
                                  d?.careStatus
                                )
                              }
                            >
                              <td style={{ width: "1%" }}>{d.schoolLa}</td>
                              <td className="school-value">{d?.schoolname}</td>
                              <td className="name-value">{d?.fullname}</td>

                              <td className="gender-value">{d?.gender}</td>

                              <td className="hidden xl:table-cell">{d?.upn}</td>

                              {/* <td className="hidden xl:table-cell">
                              {d.vsnumber}
                            </td> */}
                              {/* <td className="hidden xl:table-cell">
                              {d.vsnumber}
                          </td> */}
                              <td>{d?.dob}</td>
                              <td>{d?.yeargroup}</td>
                              <td className="hidden xl:table-cell">
                                {d?.vsnumber}
                              </td>
                              <td>{d?.careStatus}</td>
                              <td>{d?.leavers}</td>
                            </tr>
                          )
                        );
                      })}
                  </tbody>

                  <tfoot>
                    {searchResults &&
                      searchResults.length > searchResultsPerPage && (
                        <tr>
                          <td colSpan="2" style={{ paddingTop: "1rem" }}>
                            <p className="tableresult">
                              {`Showing ${currentResults.length} to ${searchResults.length} of ${searchResults.length} entries`}{" "}
                              {
                                <Pagination
                                  resultsPerPage={searchResultsPerPage}
                                  totalRecords={
                                    searchResults ? searchResults.length : 0
                                  }
                                  paginate={paginate}
                                  currentPage={currentPage}
                                />
                              }
                            </p>
                          </td>
                        </tr>
                      )}
                  </tfoot>
                </>
              )}
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(SearchBar);
