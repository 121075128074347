import React, { useEffect, useState, useContext } from "react";
import Header from "../../Components/Header2";
import { Row, Col, Button, Select } from "antd";
import { settings } from "../../Components/utils/Settings";
import axios from "axios";
import Loader from "react-js-loader";
import Footer from "../../Components/Footer";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import FileSaver from "file-saver";
import PupilContext from "../../Components/context/PupilContext";
import { Link, useHistory } from "react-router-dom";

const Summary = (props) => {
  const history = useHistory();
  const [clientsArray, setclientsArray] = useState([]);
  const [KidsTotal, setKidsTotal] = useState("");
  const [DTotal,setDTotal] = useState("")
  const [MissingData, setMissingData] = useState("");
  const [MissingSess, setMissingSess] = useState("");
  const [MissingSessper, setMissingSessper] = useState("");
  const [countschool, setcountschool] = useState("");
  const [wondCountt, setwondCountt] = useState("");
  const [perWonde, setperWonde] = useState("");
  const [WondeSchool, setWondeSchool] = useState("");
  const [schoolonwonde, setschoolonwonde] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [AcdValue,setAcdValue] = useState('2024-25')
  const {
    setSchoolsId,
    setSortingValue,
    cictype,
    setcictype,
    setselectedClient,
    setdeletevalues,
    setKS5,
  } = useContext(PupilContext);

  const Iduser = localStorage.getItem("userId");

  useEffect(() => {
    console.log(AcdValue)
    setclientsArray('')
    const apiurl = `${settings.API_HOST}/rest/fetchAllSchoolsSummaryData?userId=${Iduser}&academicYear=${AcdValue}`;

    axios.get(apiurl).then((res) => {
      console.log(res);

      const Allclientsdata = res?.data?.allSchoolsSummaryDataList;
      const abc = [];
      let sum = 0;
      let missum = 0;
      let sesssum = 0;
      let misspersum = 0;
      let schoolsum = 0;
      let wondesum = 0;
      let persum = 0;
      let wondeSchoolPer = 0;
      let Dtotal = 0;

      const datafortable = Allclientsdata.map((item) => {
        const kidsdata = item.kidCount;
        const sessionDAta = item?.aboveTenSessionsMissingKidCount;
        const MissingChild = item?.allSessionsMissingKidCount;
        const Missingatten = item?.allSessionsMissingPercentage;
        const schoolcount = item?.subSchoolCount;
        const wondeSchoolCount = item?.wondeKidCount;
        const wondeSchoolPercentage = item?.wondeSchoolPercentage;
        const schoolper = item?.wondeSchoolCount;
        const Dper  = item?.kidsCountWithCodeD

        // console.log(schoolper);

        sesssum += sessionDAta;
        sum += kidsdata;
        missum += MissingChild;
        misspersum += Missingatten;
        schoolsum += schoolcount;
        wondesum += wondeSchoolCount;
        persum += wondeSchoolPercentage;
        wondeSchoolPer += schoolper;
        Dtotal += Dper

        return { DataArray: item };
      });

      const newvalue = (missum / sum) * 100;

      const countperschool = (wondeSchoolPer / schoolsum) * 100;

      const wondperschool = (wondesum / sum) * 100;

      //console.log(countperschool.toFixed());

      setperWonde(countperschool.toFixed(2));
      setwondCountt(wondesum);
      setcountschool(schoolsum);
      setMissingSessper(newvalue.toFixed(2));
      setMissingSess(missum);
      setMissingData(sesssum);
      setKidsTotal(sum);
      setDTotal(Dtotal)
      setWondeSchool(wondeSchoolPer);
      setschoolonwonde(wondperschool.toFixed(2));
      setclientsArray(datafortable);
    });
  }, [AcdValue]);

  const HandleExport = () => {
    const ApiEndpoint = `${settings.API_HOST}/rest/fetchAllSchoolsSummaryDataExcel`;

    axios({
      url: ApiEndpoint,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? `Summary-report.xlsx`
            : suggestedFileName;
        FileSaver.saveAs(response.data, effectiveFileName);
        toast.success(
          "Attendance report exported and downloaded successfull as EXCEL Format",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
          }
        );
        Swal.close();
        setExportLoading(false);
      })
      .catch((e) => {
        toast.error("Sorry something went wrong ! Try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
      });
  };

  const HandleMissingsess = (e, SchoolId, name) => {
    if (name == "Lewisham Cin/Cp") {
      setcictype("CIN");
      setKS5("");
    } else if (name == "Lewisham CIC") {
      setcictype("CIC");
      setKS5("");
    } else if (name === "Barnet KS5") {
      setKS5("KS5");
    } else if (name === "Lewisham KS5") {
      setKS5("KS5");
    } else if (name === "Milton Keynes KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "NCC KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "City Of London KS5") {
      setKS5("KS5");
      setcictype("");
    } else {
      setcictype("");
      setKS5("");
    }

    console.log(name);
    //console.log(e,SchoolId)
    //setSchoolsId(SchoolId),
    setselectedClient(SchoolId);
    setSchoolsId(SchoolId)
   // setdeletevalues(true);
    setSortingValue("missingMoreThenTen");
    history.push("/attendance-caller");
  };

  const HandleDcode = (e, SchoolId, name) => {
    if (name == "Lewisham Cin/Cp") {
      setcictype("CIN");
      setKS5("");
    } else if (name == "Lewisham CIC") {
      setcictype("CIC");
      setKS5("");
    } else if (name === "Barnet KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Lewisham KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Milton Keynes KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "NCC KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "City Of London KS5") {
      setKS5("KS5");
      setcictype("");
    } else {
      setcictype("");
      setKS5("");
    }
    setdeletevalues(false);
    setselectedClient(SchoolId);
    setSchoolsId(SchoolId)
    setSortingValue("Code D Kids");
    history.push("/attendance-caller");
  }

  const HandleMissingsall = (e, SchoolId, name) => {
    console.log(name);
    if (name == "Lewisham Cin/Cp") {
      setcictype("CIN");
      setKS5("");
    } else if (name == "Lewisham CIC") {
      setcictype("CIC");
      setKS5("");
    } else if (name === "Barnet KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Lewisham KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "Milton Keynes KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "NCC KS5") {
      setKS5("KS5");
      setcictype("");
    } else if (name === "City Of London KS5") {
      setKS5("KS5");
      setcictype("");
    } else {
      setcictype("");
      setKS5("");
    }
    //setdeletevalues(true);
    setselectedClient(SchoolId);
    setSchoolsId(SchoolId)
    setSortingValue("allMissing");
    history.push("/attendance-caller");
  };

  const color = "#fff";

  return (
    <>
      {/* <div className="custom-head">
        <Header />
      </div> */}

      <h1 className="Head-title" style={{ color: "#fff" }}>
        Summary
      </h1>

      {clientsArray == "" ? (
        <div className="cube-loader">
          <Loader type="ping-cube" bgColor={color} color={color} size={100} />
        </div>
      ) : (
        <div className="table-group">
          
          <table
            className="table-auto w-100 child-table tablesection customtablenew"
            style={{ width: "61%",marginTop:'10px' }}
          >
            <Select onSelect={(e)=>{setAcdValue(e)}} 
            style={{
              position: "absolute",
             marginLeft:'15px',
             top:'10%'
            }}

            defaultValue={AcdValue}
            >
            <option value="2024-25">2024-25</option>
            <option value="2023-24">2023-24</option>
          </Select>
            <div className="head-tables pupilsno">
            
              <p className="table-title"> No of pupils</p>
            </div>
            <thead className="thead-title">
              <tr>
                <th
                  className="cursor-pointer"
                  //onClick={() => setCustomSorting("schoolname")}
                  style={{ width: "185px" }}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Clients</span>
                    {/* <span className="mr-4">{showArrow("schoolname")}</span> */}
                  </div>
                </th>
                <th
                  className="cursor-pointer aligntable"
                  style={{ textAlign: "center" }}
                  // onClick={() => setCustomSorting("urn")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Total</span>
                    {/* <span className="mr-4">{showArrow("urn")}</span> */}
                  </div>
                </th>

                <th
                  className="cursor-pointer aligntable"
                  style={{ textAlign: "center" }}
                  // onClick={() => setCustomSorting("urn")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">No of D Codes</span>
                    {/* <span className="mr-4">{showArrow("urn")}</span> */}
                  </div>
                </th>
                <th
                  className="cursor-pointer aligntable "
                  style={{ maxWidth: "150px" }}

                  // onClick={() => setCustomSorting("pupilcount")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">More than 10 sessions missing</span>
                    {/* <span className="mr-4">{showArrow("pupilcount")}</span> */}
                  </div>
                </th>
                <th
                  className="cursor-pointer aligntable"
                  style={{ maxWidth: "150px", textAlign: "center" }}
                  // onClick={() => setCustomSorting("wondcount")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Missing all attendance</span>
                    {/* <span className="mr-4">{showArrow("wondcount")}</span> */}
                  </div>
                </th>
                <th
                  className="cursor-pointer aligntable"
                  style={{ textAlign: "center", width: "212px" }}
                  // onClick={() => setCustomSorting("attendanceStatus")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">% Missing all attendance</span>
                    {/* <span className="mr-4">
                      {showArrow("attendanceStatus")}
                    </span> */}
                  </div>
                </th>

                <th
                  className="cursor-pointer aligntable"
                  style={{ textAlign: "center" }}
                  // onClick={() => setCustomSorting("attendanceStatus")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">On Wonde</span>
                    {/* <span className="mr-4">
                      {showArrow("attendanceStatus")}
                    </span> */}
                  </div>
                </th>

                <th
                  className="cursor-pointer aligntable"
                  style={{ textAlign: "center" }}
                  // onClick={() => setCustomSorting("attendanceStatus")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">% on Wonde</span>
                    {/* <span className="mr-4">
                      {showArrow("attendanceStatus")}
                    </span> */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {clientsArray?.map((items) => (
                <>
                  <tr className="align-texts">
                    <td className="schoolpupils nameofschools">
                      {items?.DataArray?.schoolName}
                    </td>
                    <td className="schoolpupils miss-session">
                      {items?.DataArray?.kidCount}
                    </td>

                    <td className="schoolpupils miss-session" id="dpupil"
                    onClick={(e) =>
                      HandleDcode(
                        e,
                        items?.DataArray?.schoolId,
                        items?.DataArray?.schoolName
                      )
                    }
                    
                    >
                      {items?.DataArray?.kidsCountWithCodeD}
                    </td>
                  
                    <td
                      className="schoolpupils miss-session"
                      id="tenpupil"
                      onClick={(e) =>
                        HandleMissingsess(
                          e,
                          items?.DataArray?.schoolId,
                          items?.DataArray?.schoolName
                        )
                      }
                    >
                      {items?.DataArray?.aboveTenSessionsMissingKidCount}
                    </td>
                    <td
                      className="schoolpupils miss-session"
                      id="allpupil"
                      onClick={(e) =>
                        HandleMissingsall(
                          e,
                          items?.DataArray?.schoolId,
                          items?.DataArray?.schoolName
                        )
                      }
                    >
                      {items?.DataArray?.allSessionsMissingKidCount}
                    </td>
                    <td className="schoolpupils miss-session">
                      {items?.DataArray?.allSessionsMissingPercentage}
                    </td>

                    <td className="schoolpupils miss-session">
                      {items?.DataArray?.wondeKidCount}
                    </td>

                    <td className="schoolpupils miss-session">
                      {items?.DataArray?.wondeKidPercentage}
                    </td>
                  </tr>
                </>
              ))}
              <tr className="align-texts">
                <td className="schoolpupils nameofschools">Grand Total</td>

                <td className="schoolpupils miss-session">{KidsTotal}</td>
                <td className="schoolpupils miss-session">{DTotal}</td>
                <td className="schoolpupils miss-session">{MissingData}</td>
                <td className="schoolpupils miss-session">{MissingSess}</td>
                <td className="schoolpupils miss-session">{MissingSessper}</td>
                <td className="schoolpupils miss-session">{wondCountt}</td>
                <td className="schoolpupils miss-session">{schoolonwonde}</td>
              </tr>
            </tbody>
          </table>
         
         
          <table
            className="table-auto w-100 child-table tablesection customtablenew"
            style={{ width: "30%",marginTop:'10px' }}
          >
            <div className="head-tables noschools">
              <p className="table-title"> No of Schools</p>
            </div>
            <Button
            className="export-button"
            shape="round"
            onClick={HandleExport}
            style={{
              position: "absolute",
               right: "5%",
              // color: "#5d45a1",
              top: "10%",
              //background: "#5d45a1",
              //marginTop: "2px",
            }}
          >
            {" "}
            {exportLoading ? <div className="spinner"></div> : "Export"}
          </Button>
            <thead className="thead-title">
              <tr>
                <th
                  className="cursor-pointer"
                  //onClick={() => setCustomSorting("schoolname")}
                  style={{ width: "185px" }}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Clients</span>
                    {/* <span className="mr-4">{showArrow("schoolname")}</span> */}
                  </div>
                </th>

                <th
                  className="total-thead aligntable"
                  //onClick={() => setCustomSorting("schoolname")}
                  style={{ minWidth: "100px", width: "3%" }}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Total</span>
                    {/* <span className="mr-4">{showArrow("schoolname")}</span> */}
                  </div>
                </th>
                <th
                  className="wonde-total aligntable"
                  // onClick={() => setCustomSorting("urn")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Total on Wonde</span>
                    {/* <span className="mr-4">{showArrow("urn")}</span> */}
                  </div>
                </th>
                <th
                  className="wonde-total aligntable"
                  style={{ width: "21%" }}

                  // onClick={() => setCustomSorting("pupilcount")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">% of Wonde</span>
                    {/* <span className="mr-4">{showArrow("pupilcount")}</span> */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="mt-4">
              {clientsArray.map((items) => (
                <tr>
                  <td className="schoolpupils nameofschools">
                    {items?.DataArray?.schoolName}
                  </td>
                  <td className="schoolpupils miss-session">
                    {items?.DataArray?.subSchoolCount}
                  </td>
                  <td className="schoolpupils miss-session">
                    {items?.DataArray?.wondeSchoolCount}
                  </td>
                  <td className="schoolpupils miss-session">
                    {items?.DataArray?.wondeSchoolPercentage}
                  </td>
                </tr>
              ))}

              <tr>
                <td className="schoolpupils nameofschools">Grand Total</td>
                <td className="schoolpupils miss-session">{countschool}</td>
                <td className="schoolpupils miss-session">{WondeSchool}</td>
                <td className="schoolpupils miss-session">{perWonde}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {/* <div className="custom-head">
        <Footer />
      </div> */}
    </>
  );
};

export default Summary;
